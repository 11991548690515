<template>
    <div class="header-wrap">
        <div class="header" ref="header">
            <img src="@/assets/img/base/header-left.png" style="width: 80px;" @click="() => { $emit('showUpdate', true) }">
            <div v-if="us" style="color: rgb(231, 110, 250);">{{ abbr(us.address, 4, 4) }}</div>
            <img v-else src="@/assets/img/logo.png" style="width: 35px;">
            <van-popover v-model:show="showPopover" trigger="click" placement="bottom-end" :overlay="true"
                :teleport="$refs.header">
                <div class="popover-wrap">
                    <div class="popover-item" v-for="(item, index) in actions" :key="index" @click="onSelect(item)">
                        <img :src="item.img" class="language-icon" />
                        <div class="language-text">{{ item.text }}</div>
                    </div>
                </div>
                <template #reference>
                    <div class="language">
                        <img :src="action.img" class="language-icon" /> 
                        <div class="language-text">{{ action.text }}</div>
                    </div>
                </template>
            </van-popover>
        </div>
    </div>
</template>
<script>
import { Popover } from 'vant';
export default {
    components: {
        [Popover.name]: Popover,
    },
    props: {
        us: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            showPopover: false,
            defaultLanguage: "ch",//默认语言
            action: {},
            actions: [
                {
                    language: "ch",
                    text: '中文',
                    img: require('@/assets/img/base/zh.png')
                },
                {
                    language: "en",
                    text: 'English',
                    img: require('@/assets/img/base/en.png')
                },
                {
                    language: "cht",
                    text: '繁體',
                    img: require('@/assets/img/base/zh.png')
                },
                {
                    language: "kor",
                    text: '한국어',
                    img: require('@/assets/img/base/kor.png')
                },
                {
                    language: "jp",
                    text: '日本語',
                    img: require('@/assets/img/base/jp.png')
                },
                {
                    language: "th",
                    text: 'ภาษาไทย',
                    img: require('@/assets/img/base/th.png')
                }
            ],
        }
    },
    watch: {

    },
    created() {
        let language = this.$utils.getloc("language")
        language = language ? language : this.defaultLanguage
        this.action = this.actions.filter(f => f.language == language)[0]

    },
    methods: {
        onSelect(e) {
            this.action = e
            let language = e.language
            this.$utils.setloc("language", language)
            this.$i18n.locale = language
            this.showPopover = false
        },
        abbr(val, start, end) {
            if (val && val.length > 20) {
                let newVal = val.replace(
                    val.substring(start, val.length - end),
                    "****"
                )
                return newVal
            } else {
                return val
            }
        },
    },
    beforeCreate() {

    }
}
</script>
<style scoped>
.header-wrap {
    position: relative;
    height: 50px;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 10px;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background-color: #080535;
}

.language {
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: url('~@/assets/img/base/language-box.png') no-repeat;
    background-size: 100% 100%;
}

.language-icon {
    width: 23px;
}

.language-text {
    margin-left: 5px;
}

.popover-wrap {
    color: #fff;
    background: #080535;
    border: 2px solid #f56ef9;
    border-radius: 10px;
    overflow: hidden;
}

.popover-item {
    display: flex;
    padding: 10px 20px;
}

.header /deep/ .van-popover {
    top: 50px !important;
    position: fixed !important;
    border-radius: 10px !important;
}
</style>