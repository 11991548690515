<template>
    <div class="paihangbang">
        <div class="paihangbang-head">
            <img class="paihangbang-head-img" src="@/assets/img/base/paihangbang.png">
            <div class="paihangbang-head-title">{{ $t('排行榜') }}</div>
        </div>
        <van-tabs v-model="active" background="#393347" color="#e96dff" title-active-color="#fff"
            title-inactive-color="#fff" @click-tab="onClickTabs">
            <van-tab :title="$t('个人销售')"></van-tab>
            <van-tab :title="$t('直推销售')"></van-tab>
            <!-- <van-tab title="WLBD"></van-tab> -->
        </van-tabs>
        <div class="list">
            <div class="list-label">
                <div class="row1">{{ $t('排名') }}</div>
                <div class="row2">{{ $t('地址') }}</div>
                <!-- <div class="row3">{{ active == 2 ? $t('持有') + 'WLBD' : $t('业绩') }}</div> -->
            </div>
            <div class="list-value" v-for="(item, index) in data" :key="index">
                <div class="row1">{{ index + 1 }}</div>
                <div class="row2">{{ abbr(item.userid, 4, 4) }}</div>
                <!-- <div class="row3">{{ item.yeji }}</div> -->
            </div>
            <empty :text="$t('暂无记录')" v-if="data.length == 0" />
            <div class="page-index">
                <div class="page-index-value" v-for="(item) in 5" :key="item" @click="onClickPageIndex(item)">{{ item }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { Tab, Tabs, Empty } from 'vant';
import CustomComponent from "@/components/CustomComponent";
export default {
    components: {
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [Empty.name]: Empty,
    },
    props: {
        pageType: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            active: ref(0),
            data: ref([]),
        }
    },
    created() {
        this.getPanghangbangData()
    },
    methods: {
        abbr(val, start, end) {
            if (val && val.length > 20) {
                let newVal = val.replace(
                    val.substring(start, val.length - end),
                    "****"
                )
                return newVal
            } else {
                return val
            }
        },
        onClickTabs(e){
            this.active = e.name
            this.pageIndex = 1
            this.data = []
            this.getPanghangbangData()
        },
        onClickPageIndex(pageindex) {
            this.pageIndex = pageindex
            this.getPanghangbangData()
        },
        getPanghangbangData() {
            let _this = this
            _this.$request.post(
                "api/Users/GetPanghangbangData",
                {
                    type: _this.active,
                    pageIndex: _this.pageIndex,
                    pageSize: 10
                },
                (res) => {
                    if (res.data.code == 0) {
                        _this.$q.dialog({
                            component: CustomComponent,
                            componentProps: {
                                messages: [res.data.msg],
                                persistent: true,
                            }
                        });
                        return
                    }
                    _this.data = res.data.data
                }
            )
        }
    }
}
</script>
<style scoped>
.paihangbang {
    margin-top: 15px;
    border-radius: 20px;
    color: #fff;
    background: #2F1D51;
    border: 2px solid #f56ef9;
}

.paihangbang-head {
    display: flex;
    align-items: center;
    padding: 15px 15px 10px;
}

.paihangbang-head-img {
    width: 25px;
    margin-right: 5px;
}

.paihangbang-head-title {
    font-size: 18px;
}

.list {
    padding: 15px 15px 20px;
}

.list-label {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #999999;
}

.list-value {
    padding: 6px 0;
    display: flex;
    color: #fff;
}

.row1 {
    flex: 1;
}

.row2 {
    flex: 6;
}

.row3 {
    flex: 3;
    text-align: right;
    overflow-x: auto;
}

.page-index {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-index-value {
    font-size: 15px;
    font-weight: bold;
    color: #f56ef9;
    padding: 0 15px;
}
</style>