<template>
	<div class="home">
		<Header :us="us" @showUpdate="(e) => { show = e }"></Header>

		<div class="swipe">
			<van-swipe :autoplay="3000" indicator-color="white">
				<van-swipe-item v-for="item in slideList" :key="item.id">
					<img :src="getimg(item.img)" class="swipe-img" />
				</van-swipe-item>
			</van-swipe>
		</div>

		<div class="q-mt-xs">
			<van-notice-bar left-icon="volume-o" background="#2F0050" color="#fff" mode="link" :text="marqueemsg"
				@click="tolink('News')" />
		</div>

		<div class="taps" style="margin-bottom: 20px;">
			<div class="taps-item" @click="tolink('Wallet')">
				<img src="@/assets/img/base/c3.png" class="taps-icon" />
				<div>{{ $t('资产') }}</div>
			</div>
			<div class="taps-item" @click="tourl('https://bnbbao.bnbaoburningecology.com/')">
				<!-- 图标尺寸不一致，特殊处理 -->
				<div class="logo-wrap">
					<img src="@/assets/img/logo.png" class="taps-icon" style="width: 70px;" />
				</div>
				<div>{{ $t('七星引流') }}</div>
			</div>
			<div class="taps-item" @click="tolink('Share')">
				<img src="@/assets/img/base/c1.png" class="taps-icon" />
				<div>{{ $t('邀请') }}</div>
			</div>
		</div>

		<!-- 价格图 -->
		<charts></charts>

		<!-- 投资 -->
		<!-- <zhubi ref="zhubiRef"></zhubi> -->

		<!-- <Touzi ref="touziRef" /> -->
		<Burn ref="burnRef" />

		<!-- 交易 -->
		<!-- <trade ref="tradeRef" @refresh="$refs.mintageRef.load()"></trade> -->

		<!-- 侧边栏 -->
		<q-dialog v-model="show" maximized position="left">
			<sidebar @shouUpdate="(e) => { show = e }"></sidebar>
		</q-dialog>

		<check-register @refresh="refresh"></check-register>

		<Paihangbang />

		<Youqinglianjie />
	</div>
</template>

<script>
import { Swipe, SwipeItem, NoticeBar, Popup } from 'vant';
// import Zhubi from './components/Zhubi.vue'
// import Touzi from './components/Touzi.vue'
// import Trade from './components/Trade.vue'
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import Charts from './components/Charts.vue'
import Youqinglianjie from './components/Youqinglianjie.vue'
import Paihangbang from './components/Paihangbang.vue'
import CheckRegister from '../../components/CheckRegister'
import Burn from './components/Burn'

import CustomComponent from "@/components/CustomComponent";

export default {
	name: '',
	components: {
		Header,
		[Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[NoticeBar.name]: NoticeBar,
		[Popup.name]: Popup,
		// Zhubi,
		// Touzi,
		// Trade,
		Sidebar,
		Charts,
		Youqinglianjie,
		Paihangbang,
		CheckRegister,
		Burn
	},
	data() {
		return {
			us: null,
			show: false,
			slideList: [],
			marqueemsg: "",
			swapInfo: {}
		}
	},
	created() {
		this.getslide()
		this.getgg()
	},
	methods: {
		tourl(url) {
			window.open(url)
		},
		onClick() {
			this.$q.dialog({
				component: CustomComponent,
				componentProps: {
					messages: ["即将上线"],
					persistent: true,
				}
			});
		},
		tolink(path) {
			this.$router.push(path)
		},
		getimg: function (img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		},
		refresh() {
			this.us = JSON.parse(this.$utils.getloc("us"))
			// this.$refs.tradeRef.load()
			// this.$refs.zhubiRef.load()
			// this.$refs.touziRef.load()
			// this.getSwapInfo()
			this.$refs.burnRef.load()
		},
		getSwapInfo() {
			let _this = this
			_this.$request.post(
				"api/Token/GetSwapInfo",
				{
					
					userid: _this.us.userid
				},
				(res) => {
					if (res.data.code == 0) {
						_this.$q.dialog({
							component: CustomComponent,
							componentProps: {
								messages: [res.data.msg],
								persistent: true,
							}
						});
						return
					}
					let data = res.data.data
					_this.swapInfo = data
					// console.log(data)
				}
			)
		},
		getslide() {
			let _this = this
			_this.$request.post(
				"Api/Slide/List",
				{
					lx: 1,
				},
				(res) => {
					if (res.data.code == 0) {
						_this.$q.dialog({
							component: CustomComponent,
							componentProps: {
								messages: [res.data.msg],
								persistent: true,
							}
						});
						return
					}
					let data = res.data.data
					_this.slideList = data
				}
			)
		},
		getgg() {
			let _this = this
			_this.$request.post(
				"Api/News/Gonggao",
				{},
				(res) => {
					if (res.data.code == 0) {
						_this.$q.dialog({
							component: CustomComponent,
							componentProps: {
								messages: [res.data.msg],
								persistent: true,
							}
						});
						return
					}
					let data = res.data.data
					_this.marqueemsg = data
				}
			)
		}
	}
}
</script>

<style scoped>
.home {
	padding: 0 10px 10px;
}

.swipe {
	margin-top: 10px;
}

.swipe-img {
	width: 100%;
	border-radius: 12px;
}

.taps {
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
}

.taps-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	text-align: center;
}

.taps-icon {
	margin-bottom: 5px;
	width: 63px;
}

.market {
	margin-top: 20px;
}

.market-item {
	margin-top: 10px;
	padding: 0 10px;
	height: 75px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	background-color: #281b54;
}

.market-icon {
	width: 50px;
}

.market-item-content {
	padding-left: 10px;
	flex: 1;
}

.market-item-row {
	display: flex;
	justify-content: space-between;
}

.market-item-text {
	font-size: 18px;
	font-weight: bold;
	color: #fff;
}

.market-item-label {
	color: #a18ee1;
}

.logo-wrap {
	display: flex;
	align-items: center;
	width: 70px;
	height: 70px;
}
</style>