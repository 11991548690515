<template>
  <div class="mintage">
    <div class="header">
      <div class="row items-center">
        <img src="@/assets/img/logo.png" class="header-icon" />
        <div class="title">{{ $t("Bnbao流量铸币销毁合约") }}</div>
      </div>
      <div class="link" @click="tolink('investRecord')">{{ $t("销毁记录") }}</div>
    </div>
    <div class="select" v-if="burnList.length > 0">
      <div class="select-list">
        <div
          :class="['select-list-item', active == index ? 'active' : '']"
          v-for="(item, index) in burnList"
          :key="index"
          @click="onClick(index)"
        >
          <img
            style="width: 45%"
            src="@/assets/img/logo.png"
          />
          <div style="margin-top: 30px; text-align: center">
            <div style="font-size: 16px; font-weight: bold">${{ item.jineMin }} ~ ${{ item.jineMax }}</div>
            <div style="font-size: 12px; color: #888">
              {{ $t("出局倍数") }} {{ item.beishu }} {{ $t("倍") }}
            </div>
            <div style="font-size: 12px; color: #888">
              {{ $t("释放比例") }}{{ item.sybiliDay }}%
            </div>
          </div>
          <img src="@/assets/img/touzi/huo.png" class="huo-icon" v-if="active == index" />
        </div>
      </div>
    </div>
    <div class="input-wrap">
      <q-input
        borderless
        v-model="jine"
        dense
        :input-style="{ color: '#fff' }"
        style="width: 100%"
        :placeholder="$t('请输入数量')"
      />
    </div>
    <div class="row text-white q-mt-sm">
      <!-- <q-radio v-model="payType" dark :val="1" color="purple-12" :label="$t('Bnbao')" class="q-mr-md" @update:model-value="radioChange" /> -->
      <q-radio v-model="payType" dark :val="2" color="purple-12" :label="$t('新Bnbao')" @update:model-value="radioChange" />
    </div>
    <div class="cell text-white" style="margin-top: 15px;">
      <div>{{ $t('可用') }}Bnbao：{{ balance }}</div>
    </div>
    <div class="row">
      <div class="btn col q-mx-xs" @click="submit">{{ $t("立即铸造") }}</div>
    </div>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import CustomComponent from "@/components/CustomComponent";
import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";
import Web3Helper from "@/assets/js/web3helper.js";

export default {
  setup() {
    return {
      us: ref(null),
      jine: ref(""),
      active: ref(0),
      burnList: ref([]),

      balance: ref(0),
      contractInfoBnbao: ref({}),
      bnbaoWeb3Helper: ref({}),
      inAddress: ref(""),

      payType: ref(2),

      showTxid: ref(false),
      txid: ref(""),
    };
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"));
    if(this.us) {
      this.getBurnList();
      this.getInfo()
    }
  },
  methods: {
    load() {
      this.us = JSON.parse(this.$utils.getloc("us"));
      this.getBurnList();
      this.getInfo()
    },
    tolink(path) {
      this.$router.push(path);
    },
    onClick(index) {
      this.active = index;
      this.jine = this.burnList[index].jineMin;
    },
    radioChange() {
      if(this.payType == 1) {
        this.bnbaoWeb3Helper = new Web3Helper(this.contractInfoBnbao.contractAbi, this.contractInfoBnbao.contractAddress);
        this.inAddress = this.contractInfoBnbao.gatherAddress
      }
      if(this.payType == 2) {
        this.bnbaoWeb3Helper = new Web3Helper(this.contractInfoBnbao.contractAbi2, this.contractInfoBnbao.contractAddress2);
        this.inAddress = "0xD991BD274F5Ebe313A408F5b83F768D2C7c821A7"
      }
      this.balanceOf()
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/BscSetting/Info",
        {
          userid: _this.us.userid
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.contractInfoBnbao = data
            // _this.bnbaoWeb3Helper = new Web3Helper(_this.contractInfoBnbao.contractAbi, _this.contractInfoBnbao.contractAddress);
            // _this.inAddress = data.gatherAddress
            _this.bnbaoWeb3Helper = new Web3Helper(_this.contractInfoBnbao.contractAbi2, _this.contractInfoBnbao.contractAddress2);
            _this.inAddress = '0xD991BD274F5Ebe313A408F5b83F768D2C7c821A7'
            _this.balanceOf(_this.us.address)
          }
        }
      )
    },
    balanceOf(address) {
      let _this = this
      _this.bnbaoWeb3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance = _this.bnbaoWeb3Helper.fromWei(wei);
      });
    },
    getBurnList() {
      let _this = this;
      _this.$request.post("api/Bnbao/List", {
        userid: _this.us.userid,
        pageIndex: 1,
        pageSize: 999
      }, (res) => {
        if (res.data.code == 100) {
          let data = res.data.data;
          _this.burnList = data
          _this.jine = data[0].jineMin
        }
      });
    },
    submit() {
      let _this = this
      if (_this.jine == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入金额"],
            persistent: true,
          }
        });
        return
      }
      if (_this.balance < _this.jine) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["钱包余额不足"],
            persistent: true,
          }
        });
        return
      }
      _this.$q.loading.show({
        message: _this.$i18n.t("铸造中...")
      })
      let payWei = _this.bnbaoWeb3Helper.toWei(_this.jine, 18);
      _this.bnbaoWeb3Helper.contractTransfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.balanceOf(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              title: err.code,
              messages: [err.message],
              persistent: true,
            }
          });
        }
      });
    }
  },
};
</script>

<style scoped>
.mintage {
  margin-top: 15px;
  padding: 15px;
  border-radius: 20px;
  background-color: #2f1d51;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-icon {
  width: 25px;
}

.title {
  margin-left: 10px;
  font-size: 18px;
  color: #fff;
}

.input-wrap {
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0d0b18;
  border-radius: 12px;
}

.input-label {
  font-size: 15px;
  color: #999;
}

.input-row {
  display: flex;
  align-items: center;
}

.link {
  color: #e96dff;
  text-decoration: underline;
}

.cell {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.select {
  margin-top: 6px;
  color: #fff;
}

.select-list {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}

.select-list-item {
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 180px;
  height: 240px;
  margin: 0 10px 10px 0;
  padding: 10px 0;
  border-radius: 5px;
  background-size: 100% 100%;
  background-image: url(../../../assets/img//touzi/border.png);
  overflow: hidden;
}

.active {
  background-size: 100% 100%;
  background-image: url(../../../assets/img//touzi/active.png);
}

.main-color {
  color: #e96dff;
}

.huo-icon {
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: -6px;
}

.q-dialog-plugin {
  max-height: calc(100vh - 108px);
  background: #000;
  color: #fff;
  border: 2px solid #f56ef9;
}

.dialog-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  padding: 16px;
}
</style>
