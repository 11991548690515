<template>
  <div class="sidebar">
    <div class="info">
      <img src="@/assets/img/logo.png" class="logo-icon" />
      <div class="q-mt-sm">{{ $t('我的地址') }}：<span>{{ abbr(us.address, 4, 4) }}</span></div>
      <div class="q-mt-xs">{{ $t('级别') }}：<span>{{ us.xlevelName }}</span></div>
    </div>

    <div class="menu">
      <div class="menu-item" @click="$emit('shouUpdate', false)">
        <div class="menu-row">
          <img src="@/assets/img/base/home.png" class="menu-icon" />
          <div class="menu-label">{{ $t('首页') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('Wallet')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu1.png" class="menu-icon" />
          <div class="menu-label">{{ $t('我的资产') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <!-- <div class="menu-item" @click="tolink('Guoku')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu6.png" class="menu-icon" />
          <div class="menu-label">{{ $t('国库兑换') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('GuokuRecord')">
        <div class="menu-row">
          <img src="@/assets/img/base/i9.png" class="menu-icon" />
          <div class="menu-label">{{ $t('国库USDT记录') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('Zhubichi')">
        <div class="menu-row">
          <img src="@/assets/img/base/i9.png" class="menu-icon" />
          <div class="menu-label">{{ $t('国库LBD铸造') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('BurnRecord')">
        <div class="menu-row">
          <img src="@/assets/img/base/i9.png" class="menu-icon" />
          <div class="menu-label">LBD{{ $t('销毁记录') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('Jingcai')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu7.png" class="menu-icon" />
          <div class="menu-label">{{ $t('哈希竞猜') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div> -->
      <div class="menu-item" @click="tolink('Bill')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu2.png" class="menu-icon" />
          <div class="menu-label">{{ $t('账单记录') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('Bonus')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu3.png" class="menu-icon" />
          <div class="menu-label">{{ $t('我的收益') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('Team')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu4.png" class="menu-icon" />
          <div class="menu-label">{{ $t('我的社区') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
      <div class="menu-item" @click="tolink('Share')">
        <div class="menu-row">
          <img src="@/assets/img/base/menu5.png" class="menu-icon" />
          <div class="menu-label">{{ $t('邀请好友') }}</div>
        </div>
        <img src="@/assets/img/base/more.png" class="more-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Sidebar',
  components: {},
  setup() {
    return {
      us: ref("")
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.sidebar {
  padding: 15px;
  width: 250px;
  height: 100vh;
  background: rgba(26, 7, 47, .9);
}

.info {
  background: url(~@/assets/img/base/info.png) no-repeat;
  background-size: 100% 100%;
}

.logo-icon {
  width: 35px;
}

.info {
  padding: 15px;
  font-size: 13px;
  color: #fff;
}

.info span {
  color: rgb(231, 110, 250);
}

.menu {
  margin-top: 10px;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #1f103c;
}

.menu-icon {
  width: 18px;
  height: 18px;
}

.more-icon {
  width: 14px;
}

.menu-item {
  margin-top: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.menu-label {
  margin-left: 10px;
}

.menu-row {
  display: flex;
  align-items: center;
}
</style>