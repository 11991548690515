<template>
    <div class="youqinglianjie">
        <div class="youqinglianjie-head">
            <img class="youqinglianjie-head-img" src="@/assets/img/base/i7.png">
            <div class="youqinglianjie-head-title">{{$t('友情链接')}}</div>
        </div>
        <div class="youqinglianjie-body">
            <a class="youqinglianjie-body-item" v-for="(item, index) in list" :key="index" :href="item.url" target="_blank">
                <img class="youqinglianjie-body-item-img" :src="item.img">
                <div>{{ item.name }}</div>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },
    data() {
        return {
            list:[{
                name:"BNB",
                url:"https://bscscan.com",
                img:require("@/assets/img/base/BNB.png")
            },{
                name:"Polygon",
                url:"https://polygonscan.com",
                img:require("@/assets/img/base/Polygon.png")
            },{
                name:"Bitcoin",
                url:"https://bitcoin.org/en",
                img:require("@/assets/img/base/Bitcoin.png")
            },{
                name:"Ethereum",
                url:"https://ethereum.org/en",
                img:require("@/assets/img/base/Ethereum.png")
            },{
                name:"Tron",
                url:"https://tronscan.io",
                img:require("@/assets/img/base/Tron.png")
            },{
                name:"Arbitrum",
                url:"https://arbitrum.io",
                img:require("@/assets/img/base/Arbitrum.png")
            }]
        }
    },
    watch: {

    },
    mounted() {

    },
    methods: {

    },
    beforeCreate() {

    }
}
</script>
<style scoped>
.youqinglianjie {
    margin-top: 15px;
    padding: 15px 15px 10px;
    border-radius: 20px;
    color: #fff;
    background: #2F1D51;
    border: 2px solid #f56ef9;
}

.youqinglianjie-head {
    display: flex;
    align-items: center;
}

.youqinglianjie-head-img {
    width: 25px;
    margin-right: 5px;
}

.youqinglianjie-head-title {
    font-size: 18px;
}

.youqinglianjie-body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;
}

.youqinglianjie-body-item {
    display: flex;
    align-items: center;
    width: 50%;
    color: inherit;
    margin-bottom: 15px;
    padding-left: 25px;
}

.youqinglianjie-body-item-img {
    width: 40px;
    margin-right: 5px;
}
</style>